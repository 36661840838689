import React, { useEffect, useState, useRef } from 'react';
import MainLayout from '../../layouts/MainLayout';
import previewConfig from './previewConfig';
import CreateComponentPreview from './CreateComponentPreview';
import gqlmin from 'gqlmin';

const EMPTY_QUERY = { space: null, contentModel: null, id: null };
const EMPTY_DATA = {};

const CmsPreview = ({ pageContext }) => {
  // The previewToken will come from vhub-contentful-config. If it doesn't
  // exist, we won't get this far and no previews are rendered
  const previewToken = pageContext.previewToken;
  const contentfulTags = pageContext.contentfulTags;

  // The query values taken from the url, and used to get the entry data.
  const [query, setQuery] = useState(EMPTY_QUERY);
  const [previewData, setPreviewData] = useState(EMPTY_DATA);
  const [isSameTag, setIsSameTag] = useState(true);
  const [hasHydrated, setHasHydrated] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState();
  const [tagCategoryCollection, setTagCategoryCollection] = useState([]);
  // refs
  const needsWrapper = useRef(true);
  // constants
  const isLoaded = previewData !== EMPTY_DATA;
  const isValid =
    previewData.hasOwnProperty('data') && !previewData.hasOwnProperty('errors') && isSameTag;
  const canQueryAPI =
    query.space !== null && query.contentModel !== null && query.id !== null;

  if (isLoaded) {
    console.log('previewData: ', previewData);
  }

  useEffect(() => {
    // On first page impression (i.e. after ssr), we can
    // start accessing the browser url.
    const paramsObj = new URLSearchParams(location.search);
    const query = {
      space: paramsObj.get('space'),
      contentModel: paramsObj.get('content-model'),
      id: paramsObj.get('id'),
    };
    setQuery(query);
    if (
      query.space !== null &&
      query.contentModel !== null &&
      query.id !== null
    ) {
      needsWrapper.current = previewConfig[query.contentModel].needsWrapper;
      getCurrentlyEditedEntryTags(previewToken, query)
      getRecaptchaToken(previewToken, query)
      getSectionElementTagCategoryCollection(previewToken, query)
      getPreviewApiData(previewToken, query, setPreviewData);
    }

    setHasHydrated(true);
  }, []);

  const content = (
    <>
      {!isLoaded && canQueryAPI && (
        <div>
          <div>
            {/* The page is either in ssr or has just loaded from a link in contentful, but not yet read its url */}
            <p>Loading preview...</p>
          </div>
        </div>
      )}
      {isLoaded && isValid && needsWrapper.current && (
        <div>
          <div>
            {/* The page has successfully loaded the data - render with wrapper*/}
            <CreateComponentPreview
              contentModelName={query.contentModel}
              data={previewData.data[query.contentModel]}
              fullData={previewData}
              recaptchaToken={recaptchaToken}
              tagCategoryCollection={tagCategoryCollection}
            />
          </div>
        </div>
      )}
      {isLoaded && isValid && !needsWrapper.current && (
        <>
          {/* The page has successfully loaded the data - render without wrapper*/}
          <CreateComponentPreview
            contentModelName={query.contentModel}
            data={previewData.data[query.contentModel]}
            fullData={previewData}
            recaptchaToken={recaptchaToken}
            tagCategoryCollection={tagCategoryCollection}
          />
        </>
      )}
      {isLoaded && !isValid && (
        <div>
          <div>
            {/* The page has unsuccessfully loaded the data*/}
            <p>
              Whoops! We tried to build your preview but the computer says 'no'.
            </p>
            {!isSameTag && <p>Please select the "{contentfulTags}" preview platform.</p>}
          </div>
        </div>
      )}
      {!isLoaded && !canQueryAPI && (
        <div>
          <div>
            {/* The user has navigated directly to /previews */}
            {hasHydrated && (
              <p>
                Whoops! You have directly navigated to this page, but this page
                only works as a <em>Contentful CMS preview</em>. You might want
                to go{' '}
                <strong>
                  <a href={location.protocol + '//' + location.host}>home</a>
                </strong>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );

  if (!needsWrapper.current) {
    return content;
  }
  return (
    <MainLayout>
      {content}
    </MainLayout>
  );

function getRecaptchaToken(previewToken, query) {
  const gqlQuery = JSON.stringify({
    query: `
    query myQuery ($preview: Boolean, $tags: String) {
      ${previewConfig.getRecaptchaToken.query}
    }`,
    variables: { preview: true, tags: contentfulTags },
  });


  fetch(
    `https://graphql.contentful.com/content/v1/spaces/${query.space}?access_token=${previewToken}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: gqlQuery,
    }
  )
    .then(result => result.json())
    .then(result => {
      if (result.data) {
        setRecaptchaToken(result.data.recaptchaTokenCollection.items[0].token)
      }
    })
    .catch(errors => console.log('errors: ', errors));
}

function getSectionElementTagCategoryCollection(previewToken, query) {
  const gqlQuery = JSON.stringify({
    query: `
    query myQuery ($preview: Boolean, $tags: String) {
      ${previewConfig.getSectionElementTagCategoryCollection.query}
    }`,
    variables: { preview: true, tags: contentfulTags },
  });


  fetch(
    `https://graphql.contentful.com/content/v1/spaces/${query.space}?access_token=${previewToken}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: gqlQuery,
    }
  )
    .then(result => result.json())
    .then(result => {
      if (result.data) {
        setTagCategoryCollection(result.data.sectionElementTagCategoryCollection.items)
      }
    })
    .catch(errors => console.log('errors: ', errors));
}

function getCurrentlyEditedEntryTags(previewToken, query) {
  const gqlQuery = JSON.stringify({
    query: `
    query myQuery ($preview: Boolean, $id: String!) {
      ${previewConfig.getCurrentlyEditedEntryTags.query}
    }`,
    variables: { preview: true, id: query.id },
  });


  fetch(
    `https://graphql.contentful.com/content/v1/spaces/${query.space}?access_token=${previewToken}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: gqlQuery,
    }
  )
    .then(result => result.json())
    .then(result => {
      const currentlyEditedEntryTags = result.data.entryCollection.items[0].contentfulMetadata.tags[0].id;
      if (currentlyEditedEntryTags === contentfulTags) {
        setIsSameTag(true);
        return;
      }
      setIsSameTag(false);
    })
    .catch(errors => console.log('errors: ', errors));
}

  function getPreviewApiData(previewToken, query, successCallback) {
    // NB - this function may have to move to a middleware, hence it is written as a self contained mode function.
    const queryFields = previewConfig[query.contentModel].query;
    const useDefaultQueryWrapper = previewConfig[query.contentModel].useDefaultQueryWrapper
    let gqlQuery = null;
    let myQuery = null;

    if (useDefaultQueryWrapper) {
      myQuery = `
        query myQuery ($preview: Boolean, $id: String!) {
          ${query.contentModel} (preview: $preview, id: $id) {
            ${queryFields}
          }
        }
      `
      const minifiedQuery = gqlmin(myQuery);
      gqlQuery = JSON.stringify({
        query: minifiedQuery,
        variables: { preview: true, id: query.id },
      });
    } else {
      myQuery = `
        query myQuery ($preview: Boolean, $id: String!, $tags: String) {
          ${queryFields}
        }
      `
      const minifiedQuery = gqlmin(myQuery);
      gqlQuery = JSON.stringify({
        query: minifiedQuery,
        variables: { preview: true, id: query.id, tags: contentfulTags },
      });
    }

    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${query.space}?access_token=${previewToken}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: gqlQuery,
      }
    )
      .then(result => result.json())
      .then(result => successCallback(result))
      .catch(errors => console.log('errors: ', errors));
  }
}

export default CmsPreview;
