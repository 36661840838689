const nestProperties = (data, propertyNames) => {
  propertyNames.forEach(propertyName => {
    if (
      propertyName in data &&
      (data[propertyName] === null || typeof data[propertyName] !== 'object')
    ) {
      const nest = {};
      nest[propertyName] = data[propertyName];
      data[propertyName] = nest;
    }
  });
  return data;
};

function makeGatsbyImageData(data) {
  const imageUrl = data.url;
  const imageData = {
    ...data,
    ...{ gatsbyImageData: { images: { sources: [] } } },
  };
  imageData.gatsbyImageData.images.sources.push({ srcSet: imageUrl });
  return imageData;
}

function convertSectionElementRichTextToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    text: {
      ...item.text,
      raw: item.text ? JSON.stringify(item.text.json) : '',
    },
  };
}

function convertSectionElementCtaButtonRowToGatsby(item) {
  const buttons = item.buttonsCollection
    ? item.buttonsCollection.items.map(button =>
        nestProperties(button, ['customAttributeData', 'seoEventData'])
      )
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    buttons: buttons,
  };
}

function convertSectionHeroWithTextAndCtaToGatsby(item) {
  const content = item.contentCollection
    ? item.contentCollection.items.map(contentCollect => {
        if (contentCollect.__typename === 'SectionElementRichText') {
          return convertSectionElementRichTextToGatsby(contentCollect);
        }
        if (contentCollect.__typename === 'SectionElementCtaButtonRow') {
          return convertSectionElementCtaButtonRowToGatsby(contentCollect);
        }
      })
    : [];

  const images = item.responsiveImageCollectionCollection
    ? item.responsiveImageCollectionCollection.items.map(data => {
        data.imageTitle = {
          ...data.imageTitle,
          file: {
            url: data?.imageTitle?.url || '',
          },
        };
        if (data?.secondImage?.url) {
          data.secondImage.file = { url: data.secondImage.url };
        }
        return data;
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    content: content,
    responsiveImageCollection: images,
  };
}

function convertSectionElementBreadcrumbToGatsby(item) {
  const id = item.sys.id;
  const content = item.contentCollection
    ? item.contentCollection.items.map(contentCollect => {
        return {
          id: contentCollect.sys.id,
          label: contentCollect.label,
          href: contentCollect.href,
          external: contentCollect.external,
        };
      })
    : [];

  const data = {
    ...item,
    __typename: 'ContentfulSectionBreadcrumb',

    id,
    content,
  };
  return data;
}

function convertSectionFeatureToGatsby(item) {
  const formattedFeatureGrid = item.featureGridCollection
    ? item.featureGridCollection.items.map(featureGridCollection => {
        return {
          id: featureGridCollection.sys.id,
          iconRef: featureGridCollection.iconRef,
          name: featureGridCollection.name,
          linkSlug: featureGridCollection.linkSlug,
          body: { body: featureGridCollection.body },
          seoEventData: { seoEventData: featureGridCollection.seoEventData },
        };
      })
    : [];

  const data = {
    ...item,
    id: item.sys.id,
    __typename: `Contentful${item.__typename}`,
    featureGrid: formattedFeatureGrid,
  };
  return data;
}

function convertSectionElementHeaderToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
  };
}

function convertTagsToGatsby(tags) {
  return tags
    ? tags.items.map(tag => ({
        name: tag.name,
        displayName: tag.displayName || tag.name,
      }))
    : [];
}

function convertPageArticleToGatsby(item) {
  const formattedThumb = {
    ...item.articleThumbnailImage,
    image: {
      ...item.articleThumbnailImage.image,
      file: {
        details: {
          image: {
            height: item.articleThumbnailImage.image.height,
            width: item.articleThumbnailImage.image.width,
          },
        },
      },
      gatsbyImageData: makeGatsbyImageData(item.articleThumbnailImage.image),
    },
  };

  const data = {
    ...item,
    id: item.sys.id,
    createdAt: new Date(item.sys.createdAt).toLocaleDateString('en-GB'),
    __typename: `Contentful${item.__typename}`,
    categoryTags: convertTagsToGatsby(item.categoryTagsCollection),
    topicTags: convertTagsToGatsby(item.topicTagsCollection),
    articleThumbnailImage: formattedThumb,
    pageArticleName: item.name,
  };

  return data;
}

function convertSectionElementVideoArticleToGatsby(item) {
  const formattedVideoSummary = {
    raw: item.videoSummary ? JSON.stringify(item.videoSummary.json) : '',
  };
  const data = {
    ...item,
    id: item.sys.id,
    __typename: `Contentful${item.__typename}`,
    videoSummary: formattedVideoSummary,
  };
  return data;
}

function convertCardToGatsby(item) {
  const formattedCardSummary = {
    raw: item.cardSummary ? JSON.stringify(item.cardSummary.json) : '',
  };

  const formattedIconsAndText = item.iconsAndTextCollection
    ? item.iconsAndTextCollection.items.map(icon => ({
        ...icon,
      }))
    : [];

  const gatsbyImage = { images: { fallback: { src: item.mainImage.url } } };

  const data = {
    ...item,
    id: item.sys.id,
    __typename: 'ContentfulSectionElementCard',
    cardSummary: formattedCardSummary,
    iconsAndText: formattedIconsAndText,
    mainImage: {
      ...item.mainImage,
      gatsbyImageData: gatsbyImage,
    },
  };
  return data;
}

function convertSectionElementArticleIndexToGatsby(item) {
  const formattedArticles = item.articlesCollection
    ? item.articlesCollection.items.map(articlesCollection => {
        switch (articlesCollection.__typename) {
          case 'PageArticle': {
            return convertPageArticleToGatsby(articlesCollection);
          }
          case 'SectionElementVideoArticle': {
            return convertSectionElementVideoArticleToGatsby(
              articlesCollection
            );
          }
          case 'Card': {
            return convertCardToGatsby(articlesCollection);
          }

          default: {
            return articlesCollection;
          }
        }
      })
    : [];

  const data = {
    ...item,
    id: item.sys.id,
    __typename: `Contentful${item.__typename}`,
    articles: formattedArticles,
  };
  return data;
}

function convertSectionTitledArticleIndexListToGatsby(item) {
  const formattedContentCollection = item.contentCollection.items.map(
    contentCollect => {
      const content = contentCollect.contentCollection.items.map(
        contentItem => {
          switch (contentItem.__typename) {
            case 'SectionElementHeader': {
              return convertSectionElementHeaderToGatsby(contentItem);
            }
            case 'SectionElementRichText': {
              return convertSectionElementRichTextToGatsby(contentItem);
            }
            case 'SectionElementArticleIndex': {
              return convertSectionElementArticleIndexToGatsby(contentItem);
            }
            case 'SectionElementCtaButtonRow': {
              return convertSectionElementCtaButtonRowToGatsby(contentItem);
            }
          }
        }
      );
      return {
        content,
        id: item.sys.id,
      };
    }
  );

  const data = {
    ...item,
    __typename: 'ContentfulSectionTitledArticleIndexList',
    content: formattedContentCollection,
    id: item.sys.id,
  };
  return data;
}

function convertSectionCtaToGatsby(item) {
  const formattedCTA = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        if (contentCollection.__typename === 'SectionElementRichText') {
          return convertSectionElementRichTextToGatsby(contentCollection);
        }
        if (contentCollection.__typename === 'SectionElementCtaButtonRow') {
          return convertSectionElementCtaButtonRowToGatsby(contentCollection);
        }
      })
    : [];

  const data = {
    ...item,
    id: item.sys.id,
    __typename: `Contentful${item.__typename}`,
    content: formattedCTA,
  };

  return data;
}

function convertSectionFilterableArticleIndexToGatsby(item) {
  const formattedArticleIndex = item.articleIndexCollection
    ? item.articleIndexCollection.items.map(articleIndexCollection => {
        return convertSectionTitledArticleIndexListToGatsby(
          articleIndexCollection
        );
      })
    : [];

  const gatsbyImage = {
    images: {
      fallback: {
        src: item.uiImageNoResultsFound ? item.uiImageNoResultsFound.url : '',
      },
    },
  };

  const data = {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    articleIndex: formattedArticleIndex,
    tagCategories: convertTagsToGatsby(item.tagCategoriesCollection),
    tagTopics: convertTagsToGatsby(item.tagTopicsCollection),
    uiImageNoResultsFound: {
      ...item.uiImageNoResultsFound,
      gatsbyImageData: gatsbyImage,
    },
  };

  return data;
}

function convertSectionElementInputFieldToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    hint: { hint: item.hint },
  };
}

function convertSectionIconLinkBarToGatsby(item) {
  const formattedIcons = item.iconsCollection
    ? item.iconsCollection.items.map(icon => ({
        ...icon,
        id: icon.sys.id,
      }))
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    icons: formattedIcons,
  };
}

function convertSectionFormToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        switch (contentCollection.__typename) {
          case 'SectionElementHeader': {
            return convertSectionElementHeaderToGatsby(contentCollection);
          }
          case 'SectionElementRichText': {
            return convertSectionElementRichTextToGatsby(contentCollection);
          }
          case 'SectionElementCtaButtonRow': {
            return convertSectionElementCtaButtonRowToGatsby(contentCollection);
          }
          case 'SectionElementInputField': {
            return convertSectionElementInputFieldToGatsby(contentCollection);
          }
          case 'SectionIconLinkBar': {
            return convertSectionIconLinkBarToGatsby(contentCollection);
          }
        }
      })
    : [];

  const formattedOperationDays = item.operatingDaysCollection
    ? item.operatingDaysCollection.items.map(operatingDaysCollection => {
        return {
          ...operatingDaysCollection,
          __typename: `Contentful${operatingDaysCollection.__typename}`,
          id: operatingDaysCollection.sys.id,
        };
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    seoEventData: { seoEventData: item.seoEventData },
    contentful_id: item.sys.id,
    operatingDays: formattedOperationDays,
    content: formattedContent,
  };
}

function convertSectionInfoToGatsby(item) {
  const gatsbyImage = { images: { fallback: { src: item.image.url } } };
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        return {
          ...contentCollection,
          __typename: `Contentful${contentCollection.__typename}`,
          buttons: contentCollection.buttonsCollection.items,
        };
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    content: formattedContent,
    image: {
      ...item.image,
      gatsbyImageData: gatsbyImage,
    },
  };
}

function convertSectionElementFeatureImageToGatsby(item) {
  const gatsbyImage = { images: { fallback: { src: item.mainImage.url } } };
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    mainImage: {
      ...item.mainImage,
      gatsbyImageData: gatsbyImage,
    },
  };
}

function convertSectionElementFeatureVideoToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
  };
}

function convertSectionFeatureCardRowToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        switch (contentCollection.__typename) {
          case 'SectionElementFeatureImage': {
            return convertSectionElementFeatureImageToGatsby(contentCollection);
          }
          case 'SectionElementRichText': {
            return convertSectionElementRichTextToGatsby(contentCollection);
          }
          case 'SectionElementFeatureVideo': {
            return convertSectionElementFeatureVideoToGatsby(contentCollection);
          }
        }
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    content: formattedContent,
  };
}

function convertSectionStepperToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        return {
          ...contentCollection,
        };
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    content: formattedContent,
  };
}

function convertSectionAccordionToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        return {
          ...contentCollection,
          __typename: `Contentful${contentCollection.__typename}`,
          accordionContent: {
            ...contentCollection.accordionContent,
            raw: contentCollection.accordionContent
              ? JSON.stringify(contentCollection.accordionContent.json)
              : '',
          },
        };
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    content: formattedContent,
  };
}

function convertSectionCustomStepperToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        const gatsbyImage = {
          images: { fallback: { src: contentCollection.stepImage.url } },
        };
        return {
          ...contentCollection,
          stepImage: {
            ...contentCollection.stepImage,
            gatsbyImageData: gatsbyImage,
          },
        };
      })
    : [];

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    content: formattedContent,
    buttonRow: convertSectionElementCtaButtonRowToGatsby(item.buttonRow),
  };
}

function convertSectionElementIconLinkToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
  };
}

function convertSectionElementFeatureTwoToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        switch (contentCollection.__typename) {
          case 'SectionElementHeader': {
            return convertSectionElementHeaderToGatsby(contentCollection);
          }
          case 'SectionElementIconLink': {
            return convertSectionElementIconLinkToGatsby(contentCollection);
          }
          case 'SectionElementRichText': {
            return convertSectionElementRichTextToGatsby(contentCollection);
          }
        }
      })
    : [];

  return {
    ...item,
    __typename: 'ContentfulSectionElementFeatureItem',
    id: item.sys.id,
    content: formattedContent,
  };
}

function convertSectionFeatureTwoToGatsby(item) {
  const formattedContent = item.contentCollection
    ? item.contentCollection.items.map(contentCollection => {
        switch (contentCollection.__typename) {
          case 'SectionElementCtaButtonRow': {
            return convertSectionElementCtaButtonRowToGatsby(contentCollection);
          }
          case 'SectionElementFeatureImage': {
            return convertSectionElementFeatureImageToGatsby(contentCollection);
          }
          case 'SectionElementHeader': {
            return convertSectionElementHeaderToGatsby(contentCollection);
          }
          case 'SectionElementFeatureTwo': {
            return convertSectionElementFeatureTwoToGatsby(contentCollection);
          }
        }
      })
    : [];

  return {
    ...item,
    __typename: 'ContentfulSectionFeatureRow',
    id: item.sys.id,
    content: formattedContent,
  };
}

function convertSectionSearchMainPageToGatsby(item) {
  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
  };
}

function convertSectionSearchResultPageToGatsby(item) {
  const formattedRelatedArticlesList =
    item?.articlesSection?.relatedArticlesList?.contentCollection?.items.map(
      relatedArticles => {
        return {
          content: relatedArticles?.contentCollection?.items.map(articles => {
            switch (articles.__typename) {
              case 'SectionElementHeader': {
                return convertSectionElementHeaderToGatsby(articles);
              }
              case 'SectionElementRichText': {
                return convertSectionElementRichTextToGatsby(articles);
              }
              case 'SectionElementArticleIndex': {
                return {
                  ...articles,
                  __typename: `Contentful${articles.__typename}`,
                };
              }
            }
          }),
        };
      }
    );

  return {
    ...item,
    __typename: `Contentful${item.__typename}`,
    id: item.sys.id,
    disclaimer: {
      ...item.disclaimer,
      raw: item.disclaimer ? JSON.stringify(item.disclaimer.json) : '',
    },
    articlesSection: {
      ...item.articlesSection,
      relatedArticlesList: {
        ...item.articlesSection.relatedArticlesList,
        content: formattedRelatedArticlesList,
      },
    },
  };
}

export function convertContentfulPageBuilderSectionsToGatsbyPageBuilder(item) {
  switch (item.__typename) {
    case 'SectionHeroWithTextAndCta': {
      return convertSectionHeroWithTextAndCtaToGatsby(item);
    }
    case 'SectionElementBreadcrumb': {
      return convertSectionElementBreadcrumbToGatsby(item);
    }
    case 'SectionFeature': {
      return convertSectionFeatureToGatsby(item);
    }
    case 'SectionCta': {
      return convertSectionCtaToGatsby(item);
    }
    case 'SectionTitledArticleIndexList': {
      return convertSectionTitledArticleIndexListToGatsby(item);
    }
    case 'SectionFilterableArticleIndex': {
      return convertSectionFilterableArticleIndexToGatsby(item);
    }
    case 'SectionForm': {
      return convertSectionFormToGatsby(item);
    }
    case 'SectionElementCtaButtonRow': {
      return convertSectionElementCtaButtonRowToGatsby(item);
    }
    case 'SectionIconLinkBar': {
      return convertSectionIconLinkBarToGatsby(item);
    }
    case 'SectionInfo': {
      return convertSectionInfoToGatsby(item);
    }
    case 'SectionFeatureCardRow': {
      return convertSectionFeatureCardRowToGatsby(item);
    }
    case 'SectionStepper': {
      return convertSectionStepperToGatsby(item);
    }
    case 'SectionAccordion': {
      return convertSectionAccordionToGatsby(item);
    }
    case 'SectionCustomStepper': {
      return convertSectionCustomStepperToGatsby(item);
    }
    case 'SectionFeatureTwo': {
      return convertSectionFeatureTwoToGatsby(item);
    }
    case 'SectionSearchMainPage': {
      return convertSectionSearchMainPageToGatsby(item);
    }
    case 'SectionSearchResultPage': {
      return convertSectionSearchResultPageToGatsby(item);
    }
    default:
      return item;
  }
}
